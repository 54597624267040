import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { isEmpty, uniqBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  addAriaResultToProject,
  addEntityToProject,
  createProject,
  getAllProjects,
  removeAriaResultFromProject,
  removeEntityFromProject
} from '../../../api/pages/UserProfile';
// eslint-disable-next-line import/no-cycle
import ProjectsList from '../Projects/ProjectsList';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';
import UserProfileStore from '../../../store/UserProfile';
import ProfileActions from '../../../store/UserProfile/actions';
import { projectPayloadGeneration } from '../util';

const filter = createFilterOptions();

const UserProjectList = ({ showActionButton, type, handleClick, data, inProjects, onClose }) => {
  const { dispatch } = useContext(GlobalStore);
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const location = useLocation();
  const [open, toggleOpen] = useState({ value: false, type: '' });
  const [dialogValue, setDialogValue] = useState({
    name: '',
    description: ''
  });
  const [projects, setProjects] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [loading, isLoading] = useState(false);
  const [value, setValue] = useState(null);

  const handleClose = () => {
    setDialogValue({
      name: '',
      description: ''
    });

    toggleOpen({ value: false, type: '' });
  };

  const handleProjects = async (create, projectId, projectName) => {
    try {
      isLoading(true);
      const payload = projectPayloadGeneration(data);
      let res = null;

      if (data?.type === 'ariaResult') {
        res = create
          ? await addAriaResultToProject({
              projectId,
              payload
            })
          : await removeAriaResultFromProject({ projectId, payload });
      } else {
        res = create
          ? await addEntityToProject({
              projectId,
              id: data.id,
              payload
            })
          : await removeEntityFromProject({ projectId, id: data.id, payload });
      }

      isLoading(false);
      if (res && res.data && res.data.body && !res.data.body.error) {
        if (onClose) {
          let localProjects = inProjects;
          if (create) {
            if (localProjects) {
              localProjects.push({ id: projectId, name: projectName, inProject: true });
            } else {
              localProjects = [{ id: projectId, name: projectName, inProject: true }];
            }
          } else {
            const objWithIdIndex = localProjects.findIndex(project => project.id === projectId);
            localProjects.splice(objWithIdIndex, 1);
          }
          onClose([...localProjects]);
        }
        if (create) {
          setProjects(prevVal => {
            const objWithIdIndex = prevVal?.findIndex(project => project.id === projectId);
            // eslint-disable-next-line no-param-reassign
            prevVal[objWithIdIndex].inProject = true;
            return [...prevVal];
          });
        } else {
          setProjects(prevVal => {
            const objWithIdIndex = prevVal?.findIndex(project => project.id === projectId);
            // eslint-disable-next-line no-param-reassign
            prevVal[objWithIdIndex].inProject = false;
            return [...prevVal];
          });
        }

        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: create
              ? `Added to ${projectName} successfully`
              : `Removed from ${projectName} successfully`,
            status: true,
            color: 'success'
          }
        });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      console.error(e);

      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: e.message, status: true }
      });
      if (onClose) {
        onClose();
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const newProject = async () => {
      setSubmitting(true);
      try {
        const payload = { name: dialogValue.name, description: dialogValue.description };
        const res = await createProject(payload);
        const { body, status, error, message } = res.data;

        if (!error && status === 200) {
          if (location.pathname.startsWith('/account')) {
            profileDispatch({
              type: ProfileActions.SET_PROJECTS,
              value: [
                { id: body.id, project_id: body.id, name: body.name, description: body.description }
              ]
            });
          } else {
            setProjects(prevVal => [
              { id: body.id, project_id: body.id, name: body.name, description: body.description },
              ...prevVal
            ]);
          }
          if (open.type !== 'nav') {
            await handleProjects(true, body.id, body.name);
          }
          await dispatch({
            type: Actions.SET_ALERT,
            value: { message: 'Project created successfully', status: true, color: 'success' }
          });
          handleClose();
        } else {
          throw new Error(message);
        }
      } catch (e) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { message: e.message, status: true }
        });
        handleClose();
      }
    };
    await newProject();

    setSubmitting(false);
  };
  const fetchData = async () => {
    try {
      const { status: axiosStatus, data: axiosData } = await getAllProjects();
      if (axiosStatus === 200) {
        // eslint-disable-next-line react/prop-types
        const { body, error, message, status } = axiosData;
        if (status === 200 || !error) {
          const localProjects = [];
          // eslint-disable-next-line react/prop-types
          body.forEach(project => {
            const id = 'project_id' in project ? project.project_id : project.id;
            if (inProjects) {
              // eslint-disable-next-line react/prop-types
              const indexOfProject = inProjects?.findIndex(item => {
                const addedProjectId = 'project_id' in item ? item.project_id : item.id;

                return addedProjectId === id;
              });
              if (indexOfProject !== -1) {
                localProjects.push({ ...project, id, inProject: true });
              } else {
                localProjects.push({ ...project, id, inProject: false });
              }
            } else {
              localProjects.push({ ...project, id, inProject: false });
            }
          });
          setProjects(localProjects);
        } else {
          throw new Error(message);
        }
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [inProjects, profileState?.projects]);
  useEffect(() => {
    if (profileState?.projectUpdatedTrigger) {
      fetchData();
      profileDispatch({
        type: ProfileActions.TRIGGER_PROJECT_UPDATED,
        value: false
      });
    }
  }, [profileState?.projectUpdatedTrigger]);

  useEffect(() => {
    if (profileState?.projects) {
      // Update or Add the project based on project_id.
      let updatedProject = [];
      updatedProject = [...profileState.projects];
      updatedProject.forEach(item => {
        projects.forEach(row => {
          if (row.project_id === item.project_id) {
            // eslint-disable-next-line no-param-reassign
            row.name = item.name;
            // eslint-disable-next-line no-param-reassign
            row.description = item.description;
          } else {
            updatedProject.push(row);
          }
        });
      });
      setProjects(uniqBy([...updatedProject], 'name'));
    }
  }, [profileState?.projects]);

  return (
    <>
      {type === 'nav' ? (
        <ProjectsList
          handleClick={handleClick}
          handleCreateProject={() => toggleOpen({ value: true, type: 'nav' })}
          projects={projects}
        />
      ) : (
        <Autocomplete
          sx={{
            margin: 1
          }}
          disabled={loading}
          placeholder={isSubmitting ? 'Processing....' : 'Create or search project'}
          value={value}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;

            const isExisting = options.some(option => option.name === inputValue);

            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`
              });
            }

            return filtered;
          }}
          id='project-list'
          options={projects}
          onKeyDown={e => {
            e.stopPropagation();
          }}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          renderOption={(props, { name, id, inProject }) => (
            <ListItem
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              disableripple='true'
              onClick={e => e.preventDefault()}
              secondaryAction={
                showActionButton &&
                (inProject ? (
                  <Tooltip title='Remove from project'>
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      disabled={loading}
                      onClick={async () => {
                        await handleProjects(false, id, name);
                      }}>
                      {loading && <CircularProgress size={20} sx={{ position: 'absolute' }} />}
                      <Remove />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Add to project'>
                    <IconButton
                      edge='end'
                      aria-label='add'
                      disabled={loading}
                      onClick={async () => {
                        if (id) {
                          await handleProjects(true, id, name);
                        } else {
                          toggleOpen({ value: true, type: 'input' });
                          setDialogValue({ name: value });
                        }
                      }}>
                      {loading && <CircularProgress size={20} sx={{ position: 'absolute' }} />}

                      <Add />
                    </IconButton>
                  </Tooltip>
                ))
              }>
              <ListItemButton
                onClick={async () => {
                  if (id) {
                    await handleProjects(!inProject, id, name);
                  } else {
                    toggleOpen({ value: true, type: 'input' });
                    setDialogValue({ name: value });
                  }
                }}
                disableRipple
                disableTouchRipple
                sx={{ background: 'transparent !important' }}>
                <ListItemText id={id} primary={name} />
              </ListItemButton>
            </ListItem>
          )}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              onChange={e => {
                if (typeof e.target.value === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setDialogValue({
                      name: e.target.value
                    });
                  });
                } else if (e.target.value) {
                  setDialogValue({
                    name: e.target.value
                  });
                }

                setValue(e.target.value);
              }}
              label={
                isSubmitting || loading
                  ? 'Processing....'
                  : `Create or ${isEmpty(inProjects) ? 'Add to' : 'Add / Remove from'} project`
              }
            />
          )}
        />
      )}

      <Dialog
        open={open.value}
        onClose={handleClose}
        disableEscapeKeyDown={isSubmitting}
        maxWidth='xs'
        fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new project</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Want to create a new project? <br /> Please enter the name of the project.
            </DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              fullWidth
              defaultValue={dialogValue.name}
              error={dialogValue.name.length >= 256}
              helperText={
                dialogValue.name.length >= 256
                  ? 'Project name must be less than 256 characters'
                  : ''
              }
              value={dialogValue.name}
              onChange={event =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value
                })
              }
              label='Name'
              type='text'
              variant='standard'
            />
            <TextField
              margin='dense'
              id='description'
              multiline
              minRows={1}
              fullWidth
              defaultValue={dialogValue.description}
              value={dialogValue.description}
              onChange={event =>
                setDialogValue({
                  ...dialogValue,
                  description: event.target.value
                })
              }
              label='Description'
              type='text'
              variant='standard'
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              onClick={handleClose}
              variant='contained'
              loading={isSubmitting}
              sx={{ textTransform: 'initial !important', color: 'white !important' }}>
              Cancel
            </LoadingButton>
            <LoadingButton
              type='submit'
              variant='contained'
              disabled={dialogValue.name.length >= 256 || isSubmitting}
              loading={isSubmitting}
              sx={{ textTransform: 'initial !important', color: 'white !important' }}>
              Add
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

UserProjectList.defaultProps = {
  showActionButton: false,
  type: 'list',
  handleClick: () => {}
};
UserProjectList.propTypes = {
  showActionButton: PropTypes.bool,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  inProjects: PropTypes.arrayOf(PropTypes.any),
  onClose: PropTypes.func
};
export default UserProjectList;
