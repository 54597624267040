import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import ResultsStore from '../../../../store/SearchResults';
import mergeMatchSubmission, { checkForMatchSubmission } from '../../utils/getMatchSubmissionData';
import getMatchSubmissions from '../../hooks/getMatchSubmissions';
import ResultActions from '../../../../store/SearchResults/actions';
import TimelineView from './TimlineView';
import { VivproSelect } from '../../../../components/CustomComponents/Selects';
import styles from './styles';
import SOURCE_MAPPING from './constant';
import FullScreenProcessing from '../../../SpecialityDesignation/components/FullScreenProcessing';

import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import groupIndicationTermByYear from './utils';
import PlusIcon from '../../../../assets/svgs/Icons/PlusIcon';

const IndicationApprovalTimline = ({ setScrollRefElement }: any) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [submissionYearData, setSubmissionYearData] = useState({});
  const [applicationNumberList, setApplicationNumberList] = useState<Array<string>>([]);
  const [searchTermList, setSearchTermList] = useState<Array<string>>([]);
  const [applications, setApplication] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const divRef = React.useRef(null);
  const [source, setSource] = useState<string>('all');
  const [optionList, setOptionList] = useState<Array<any>>([]);
  const { dispatch }: any = useContext(GlobalStore);

  const getSubmissionData = async () => {
    setLoading(true);
    const searchId = resultsState.searchIds?.applicationSearchId;
    const applicationResults = { ...resultsState.applicationResults };
    if (searchId && !checkForMatchSubmission(applicationResults)) {
      const submissionData = await getMatchSubmissions(searchId);

      const newApplData = mergeMatchSubmission(applicationResults, submissionData);
      resultsDispatch({
        type: ResultActions.SET_APPLICATION_RESULTS,
        value: newApplData
      });
    }
    setLoading(false);
  };

  const groupByYear = (data: any) => {
    const groupData = groupIndicationTermByYear(data, source);

    setSubmissionYearData(groupData?.result);
    setApplicationNumberList(groupData?.applList);
    setSearchTermList(groupData?.searchTerms);
    setApplication(groupData?.appArray);
  };

  useEffect(() => {
    getSubmissionData();
  }, []);

  useEffect(() => {
    if (resultsState?.applicationResults) {
      groupByYear(resultsState?.applicationResults);
    }

    const itemList: Array<any> = [
      {
        value: 'all',
        label: 'All Region'
      }
    ];
    Object.keys(resultsState?.applicationResults).forEach((key: string) => {
      if (key in SOURCE_MAPPING) {
        itemList.push({
          value: key,
          label: SOURCE_MAPPING[key]
        });
      }
    });
    setOptionList(itemList);
  }, [resultsState?.applicationResults, source]);

  const handleClick = (e: SelectChangeEvent) => {
    setSource(e.target.value);
  };

  const addToReport = React.useCallback(async () => {
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: 'INDICATION_SEQUENCING',
        sectionType: 'CHART',
        id: uniqueId(`indication_sequencing_graphs_${random}_`),
        style: {
          placement: {
            h: 28,
            w: 12
          },
          graphStyle: {},
          title: {}
        },
        data: {
          source,
          search_id: resultsState.searchIds.applicationSearchId
        }
      }
    });
    await dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  }, [dispatch, resultsState.searchIds.ctSearchId]);

  if (isLoading) {
    return (
      <FullScreenProcessing loading={isLoading} open={isLoading} message='Generating image...' />
    );
  }
  return (
    <Stack>
      <Box mr={2} sx={styles.dropDownBox}>
        <VivproSelect
          // @ts-ignore
          value={source}
          handleChange={handleClick}
          options={optionList}
          icon={KeyboardArrowDown}
        />
        <Button
          onClick={() => {
            addToReport();
          }}
          variant='text'
          startIcon={<PlusIcon sx={styles.plusIcon} />}
          fullWidth={false}
          sx={styles.add}>
          <Typography align='right' sx={styles.addText}>
            {applicationNumberList && applicationNumberList?.length >= 50
              ? ' Report (Top 50 Only)'
              : ' Report'}
          </Typography>
        </Button>
      </Box>
      {Object.keys(submissionYearData).length !== 0 ? (
        <Grid container ref={divRef} mb={3} mt={2} flexWrap='nowrap' sx={styles.gridContainer}>
          <TimelineView
            dataByYear={submissionYearData}
            drugs={applications}
            applicationNumbers={applicationNumberList}
            searchTermList={searchTermList}
            setScrollRefElement={setScrollRefElement}
            enableDownload
          />
        </Grid>
      ) : (
        <Box sx={styles.message}>
          <Typography variant='h6' color='inherit'>
            No Graphs Available
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default IndicationApprovalTimline;
